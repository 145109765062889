<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Nieuw item" />

    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">General information</h3>
            <p class="mt-1 text-sm text-gray-600">
              The button text and link can be left empty when no button should be shown.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          
          <form @submit.prevent="handleSave" method="POST">
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-6">
                    <label for="title" class="block text-sm font-medium text-gray-700">Naam campagne</label>
                    <input type="text" name="title" id="title" autocomplete="title" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" required v-model="item.title">
                  </div>
                </div>
              </div>
              
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" @click="saveItem">
                  Next
                </button>
              </div>
            </div>
          </form>
          
        </div>
      </div>
    </div>

  </main>
</template>

<script>
import HubspotService from '../../../services/admin/hubspot/service';

export default {
  name: 'HubspotNew',
  data() {
    return {
      item: {
        name: "",
      },
      submitted: false
    }
  },
  methods: {
    saveItem() {

      var data = {
        title: this.item.title
      };

      HubspotService.create(data)
        .then(data => {
          
          console.log(data.id);
          
          this.$router.push(`/hubspot/${data.id}`);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
}
</script>